import './table3.css'
import React, { useEffect,useState, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { format } from 'date-fns';
import axios from 'axios';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Modal_zaikolist from './Modal_zaikolist';

//import './TableComponent.css';

const customStyles_2 = {
  control: (provided, state) => ({
    ...provided,
    marginbottom: '10px',
    border: '1px solid #d3d3d3', /* 薄い灰色の枠線 */
    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', // フォーカス時のボーダー
    '&:hover': {
      border: '1px solid black' // ホバー時のボーダー
    }
  })
};

const initialData = [
  { date: '2024-01-01', column2: 'test1', column3: 'value3', column4: 'value4',column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  { date: '2024-02-01', column2: 'test2', column3: 'value3', column4: 'value4' ,column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  // 他のデータも追加
];

const StyledTable = styled.table`
  font-size: 12px; /* 希望のフォントサイズに変更 */
`;
function formatDate(dateString) {

  const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL_LOCAL;

  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log(dateString)
  //console.log(dateString.latest_date)
  //console.log("出力0")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function formatDate1(dateString) {
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log("出力1")
  //console.log(dateString)
  //console.log("出力1")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}年${month}月${day}日`;
}



export default  function  TableComponent3() {
  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;

  const [data, setData] = useState([]);
  const [pageSizeOptions, setPageSizeOptions] = useState([10, 20, 30, 40, 50, 100]); // ページサイズオプション
  const [initialPageSize, setInitialPageSize] = useState(100); // filteredDataの長さに基づく初期サイズ
  const [sortByState, setSortByState] = useState([]);

  useEffect(() => {
    fetchData_set();
  }, []);

  const sortedData = React.useMemo(() => {
    return data.sort((a, b) => a.order - b.order); // orderでソート
  }, [data]);

  const fetchData_set = async () => {
    

        try {
          const response = await axios.get(`${apiUrl}?action=getHatyuList`);
          console.log("発注リスト");
          console.log(response.data);
          //setData(response.data);
          updateDataWithKeikabi(response.data); // データが取得できたら計算

          console.log("発注リスト");
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      /*在庫リスト更新 */
      const updatehatyulistfromid = async (id) => {
        console.log(id);
        try {
          const response = await axios.post(`${apiUrl}?action=updateHatyuListFromId`, { id });

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


      useEffect(() => {
        console.log("Updated data:");
        console.log(data);
      }, [data]); // `data` が変更されるたびにこの useEffect が実行される

    const handleStatusChange = async (id, columnId, value,nameID,keikabi) => {
      console.log(`Updated row ${id} column ${columnId} with value ${value} atkeikabi ${keikabi}`);

      try {
        console.log(id)
         const response = await axios.post(`${apiUrl}?action=updateData_hatyulist`, { id, columnId, value ,keikabi});
         
         console.log("平均値更新")
         console.log(response)
         if (response.status === 200) {
           fetchData_set();
           console.log(nameID)
           //発注ステータス更新時は商品リスト側は変更しない。
           console.log(columnId)
           if(columnId !='Hayuust'){
            
            console.log("発注リスト更新")
           updatehatyulistfromid(nameID);
           }

         }
       } catch (error) {
         alert('データの登録に失敗しました');
         console.error('Error adding data:', error);
       }
    };

   /* const [data, setData] = useState([]);*/
   const [latestDate, setLatestDate] = useState(null);

   useEffect(() => {
     const fetchLatestDate = async () => {
       try {
         const response = await axios.get(`${apiUrl}?action=getLatestHatyuUpdate`);
         console.log(response.data.latest_date)
         setLatestDate(response.data.latest_date);
       } catch (error) {
         console.error('Error fetching latest date:', error);
       }
     };
 
     fetchLatestDate();
   }, []);


   /*データベース削除 */
   const handleButtonClick = async (row) => {
    const idToDelete = row.original.Index; // row.Indexからrow.original.Indexに変更
    const nameID = row.original.ID; 
    console.log(row.original)
    try {
      const confirmed = window.confirm("削除してよろしいですか？");
      if (confirmed) {
        // MySQL DBから該当する行を削除するリクエストを送信
        const response = await axios.post(`${apiUrl}?action=deleteHatyu`, { id: idToDelete });
        if (response.status === 200) {
          // 成功した場合、テーブルを更新
          fetchData_set(); // 最新のデータを再取得して表示
          console.log(nameID)
          updatehatyulistfromid(nameID);
          alert('行が削除されました');
        } else {
          alert('削除に失敗しました');
        }
      }
      
    } catch (error) {
      console.error('エラーが発生しました', error);
      alert('エラーが発生しました');
    }
  };

const [id, setid] = useState('');
const [name, setName] = useState('');
const [Tormaszaiko, setTormaszaiko] = useState('');
const [Shireyoteisu, setShireyoteisu] = useState('');
const [Syukkataikisu, setSyukkataikisu] = useState('');
const [Mitotyakusu, setMitotyakusu] = useState('');
const [Hituyousu, setHituyousu] = useState('');
const [kahusokusu, setkahusokusu] = useState('');
const [Shireprice, setShireprice] = useState('');
const [Hiiresaki, setHiiresaki] = useState('');
const [ShiesakiID, setShiesakiID] = useState('');	

const [Hatyuusu, setHatyuusu] = useState('');	
const [Shiiresaki_URL, SetShiiresaki_URL] = useState('');

const [color, setcolor] = useState('');
const [size, setsize] = useState('');
const [emo_p, setmemo_p] = useState('');
const [Shop_name, setShop_name] = useState('');

const [ave_nouki, Setave_nouki] = useState('');
const [show, setShow] = useState(false);
const [selectedRow, setSelectedRow] = useState(null);
const [isNewProduct, setIsNewProduct] = useState(false); // ラジオボタンの状態管理
  /*発注ボタン */
const handleButtonClick_show =async (row_ori) => {

  setSelectedRow(row_ori);
  setid(row_ori.ID)
  console.log(row_ori.ID)

  /*mysqlDBからデータを取得して代入 */
   // IDを基にデータを取得
   
   try {
    const response = await axios.get(`${apiUrl}?action=getUsers_byid&id=${encodeURIComponent(row_ori.ID)}`);
    
    if (response.status === 200 && response.data.length > 0) {
      const row = response.data[0]; // 取得したデータの最初の行を使用
      console.log(row);
  
      // 各stateを一度に更新する
      setName(row.Name);
      setTormaszaiko(row.Tormaszaiko);
      setShireyoteisu(row.Shireyoteisu);
      setSyukkataikisu(row.Syukkataikisu);
      setMitotyakusu(row.Mitotyakusu);
      setHituyousu(row.Hituyousu);
      setkahusokusu(row.kahusokusu);
      setShireprice(row.Shireprice);
      setHiiresaki(row.Hiiresaki);
      setShiesakiID(row.ShiesakiID);
      SetShiiresaki_URL(row.Shiiresaki_URL);
      Setave_nouki(row.ave_nouki);
      setcolor(row.color);
      setsize(row.size);
      setmemo_p(row.pro_memo);
      setShop_name(row.Shop_name);

      if(row.new_product=="新作"){
        setIsNewProduct(True)
      }
      else{
        setIsNewProduct(false)
      }
      
    } else {
      console.error('データが見つかりませんでした。');
    }
  } catch (error) {
    console.error('データ取得中にエラーが発生しました: ', error);
  }
   
  /*
  const client_data ={
    value: row.original.ShiesakiID,
    label:row.original.Hiiresaki,
  };

  setSelectedOption(client_data);
  */
  
  setShow(true);
};
const handleClose= () => {
  setShow(false);
  setSelectedRow(null);
};

  const handleCopy = async (row) => {
    try {
      // コピーしたデータをバックエンドに送信
      const idcopy = row.original.Index; // row.Indexからrow.original.Indexに変更
      const ordercopy = row.original.order; 
      const response = await axios.post(`${apiUrl}?action=copyhatyulist`, { id: idcopy , order: ordercopy });
      console.log("コピーデータ")
      console.log(response)
      if (response.status === 200) {
        // 挿入後にデータを再読み込み
        fetchData_set(); // 最新のデータを再取得して表示
      } else {
        console.error('Failed to insert row');
      }
    } catch (error) {
      console.error('Error copying row:', error);
    }
  };

   const columns = React.useMemo(
    () => [

      //{ Header: 'No', accessor: 'Index' },
      {
        
        Header: '発注日',
        accessor: 'datedorm',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'ショップ名',
        accessor: 'Shop_name',
      },
      {
        Header: '新作',
        accessor: 'new_product',
        Cell: ({ value }) => (
          value === '新作' ? '〇' : '' // new_productの値が「新作」なら〇、それ以外は空欄
        )
      },

      
      { Header: '品番', accessor: 'ID' },
      { Header: '商品名', accessor: 'Name' },
      
      //{ Header: '仕入先', accessor: 'Hiiresaki' },

      { Header: '入荷数', 
        accessor: 'hatyusu' ,
        Cell: ({ value, row }) => (
          <input
            type="text"
            value={value ?? ''}
            onChange={(e) => handleStatusChange(row.original.Index,'hatyusu', e.target.value,row.original.ID, e.target.value, row.original.keikabi)}
            style={{ width: '50px' }} // inputタグにwidthを追加
          />
        ),
      },

      {
        
        Header: '到着日',
        accessor: 'Totyakubi',

        Cell: ({ value, row,}) => (
          <input
            type="date"
            value={formatDate(value)}
            onChange={(e) =>  handleStatusChange(row.original.Index,'Totyakubi', e.target.value,row.original.ID,row.original.keikabi)}
          />
        ),


      },

      {
        Header: '発注',
        accessor: 'Hayuust',
        Cell: ({ row, value }) => (
          <select
            value={value || ''}
            onChange={(e) => {
              const newValue = e.target.value;

              // 発注状況が「到着済み」に変更された場合、かつTotyakubiが空の場合のみ更新
              if (newValue === '到着済み' && !row.original.Totyakubi) {
                // 日本時間 (JST: UTC+9) で本日の日付を取得
                  const today = new Date();
                  today.setHours(today.getHours() + 9); // UTC+9に調整
                  const jstToday = today.toISOString().split('T')[0]; // YYYY-MM-DD形式に変換
                handleStatusChange(row.original.Index, 'Totyakubi', jstToday, row.original.ID, row.original.keikabi);
              }

              // 発注ステータスの変更
              handleStatusChange(row.original.Index, 'Hayuust', newValue, row.original.ID, row.original.keikabi);
            }}
          >
            <option value="発注済み">発注済み</option>
            <option value="到着済み">到着済み</option>
          </select>
        ),
      },
      /*
      { Header: 'メモ', 
        accessor: 'Memo	',
        Cell: ({ value, row }) => (
          <input
            type="text"
            value={value || ''}
            onChange={(e) => handleStatusChange(row.original.Index,'Memo',e.target.value,)}
            style={{ width: '150px' }} // inputタグにwidthを追加
          />
        ),


      },*/
      /*
      {
        Header: 'URL',
        accessor: 'Shiiresaki_URL',
        getHeaderProps: () => ({
          className: 'custom-header',
        }),
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },*/

      /*
  { Header: '仕入先', 
    accessor: 'Hiiresaki',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },*/

   {
    Header: '仕入先',
    accessor: 'Hiiresaki',
    Cell: ({ value, row }) => (
      <button 
        className="bu_shousai1-1-s" 
        onClick={() => handleButtonClick_show(row.original)} // `row.original` を渡す
      >
        {value}
      </button>
    )
  },
  {
    Header: '注文ID',
    accessor: 'tyumon_ID',
  },


   
      {
        Header: '経過日数',
        accessor: 'keikabi',
      },


      /*{
        Header: '経過日数',
        accessor: 'keikabi',
        Cell: ({ row }) => {
          const hayuust = row.original.Hayuust;
          const orderDate = row.original.datedorm;
          
          // 経過日数を計算
          const calculateDaysElapsed = (orderDate) => {
            const today = new Date();
            const orderDateObj = new Date(orderDate);
            const timeDifference = today - orderDateObj;
            const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) +1;
            return daysElapsed;
          };
      
          // Hayuustの状態によって表示を切り替え
          if (hayuust === '到着済み') {
            return 0;
          } else if (hayuust === '発注済み' && orderDate) {
            return calculateDaysElapsed(orderDate);
          } else {
            return ''; // 発注日がない場合など
          }
        },
      },*/

       


      { Header: '削除', accessor: 'C_button1', 
        Cell: ({ row }) => (
          <button className ="bu_shousai1-1" onClick={() => handleButtonClick(row)}>削除</button>
        )
      },
      {
        Header: '複製',
        accessor: 'Copy',
        Cell: ({ row }) => (
          <button className ="bu_shousai1-2" onClick={() => handleCopy(row)}>Copy</button>
        )
      }


    ],
    []
  );
  
  // 経過日数を計算し、データに追加する関数
const updateDataWithKeikabi = (data_) => {
  const updatedData = data_.map(row => {
    const hayuust = row.Hayuust;
    const orderDate = row.datedorm;
    const Totyakubi = row.Totyakubi;

    // 経過日数を計算
    const calculateDaysElapsed = (startDate, endDate) => {
      const startDateObj = new Date(startDate);
      const endDateObj = endDate ? new Date(endDate) : new Date();
      const timeDifference = endDateObj - startDateObj;
      const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
      return daysElapsed;
    };

    let keikabiValue = '';

    // Totyakubi（到着日）が存在すれば、それを基準に経過日数を計算
    if (hayuust === '発注済み' && orderDate) {
      // Totyakubiがない場合は、発注日から現在までの経過日数を計算
      keikabiValue = calculateDaysElapsed(orderDate);
    }
    else if (Totyakubi && orderDate) {
      keikabiValue = calculateDaysElapsed(orderDate, Totyakubi);
    } else if (hayuust === '到着済み') {
      keikabiValue = 0;
    }

    return { ...row, keikabi: keikabiValue };
  });

  setData(updatedData);
};

 

  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
 
 //console.log("updateData")
 const [filterType, setFilterType] = useState('発注済み'); // フィルタの初期値を '不足' に設定

 const filterOptions = [
   { value: '全体', label: '全体' },
   { value: '発注済み', label: '発注済み' },
 ];
 // フィルタドロップダウンの選択ハンドラ
   const handleFilterChange = selectedOption => {
     setFilterType(selectedOption.value);
   };

  const filteredData = useMemo(() => {
    return sortedData.filter(row => {
      const date = new Date(row.datedorm);
      const isWithinDateRange =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);
      const matchesSearchInput = Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
      
        // "不足" フィルタ条件
      const matchesFilterType = filterType === '発注済み' ? row.Hayuust == "発注済み" : true;

      return isWithinDateRange && matchesSearchInput && matchesFilterType;
    });
  }, [sortedData, searchInput, startDate, endDate,filterType]);


   // 100の位で丸める関数（切り上げ）
   const roundUpToHundred = (num) => {
    return Math.ceil(num / 100) * 100;
  };

  // filteredDataが更新されたときに、ページサイズオプションを更新する
 
  useEffect(() => {
    const maxPageSize = roundUpToHundred(filteredData.length);
    if(maxPageSize>100){
    setPageSizeOptions([10, 20, 30, 40, 50, 100, maxPageSize]);
    setInitialPageSize(maxPageSize); // ページサイズの初期値をmaxPageSizeに更新
    setPageSize(maxPageSize)
  }

  }, [filteredData]); // filteredDataが更新されるたびに実行

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    //state: { pageIndex, pageSize },
    state,
  } = useTable(
    {
      columns,

      data : filteredData,
      //initialState: { pageIndex: 0 },
      initialState: { pageIndex: 0 , pageSize:initialPageSize, sortBy: sortByState },
      //updateData,
    },
    useSortBy,
    usePagination
  );
    // ソート状態が変わるたびに保存する
  // stateからsortByを取得
  const { pageIndex, pageSize, sortBy } = state;
  useEffect(() => {
    setSortByState(sortBy); // sortByで現在のソート状態を取得
  }, [sortBy]);
  return (
    <div>
      <span>最新更新日:　{formatDate1(latestDate)}</span>
      <div className="controls">
        <div>フリーワード検索</div>
        <input
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search"
          className="controls_date"
        />
        <div>日付</div>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="controls_date"
        />

        <div>～</div>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          className="controls_date"
        />
      </div>
      {/* フィルタドロップダウン */}
      <div className = "c-button-fusoku">
        <div className = "button-fusoku">
          <Select
            value={filterOptions.find(option => option.value === filterType)}
            onChange={handleFilterChange}
            options={filterOptions}
            styles={customStyles_2}
            placeholder="未到着フィルタ"
          />
        </div>
      </div>
      <div className="pagination">
        <button id = "pg_bu1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span id="pg_sp">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>

      </div>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  backgroundColor: '#f2f2f2', // 背景色を設定
                  color: 'black', // 文字色を設定
                  padding: '10px', // パディングを設定
                  //textAlign: 'left', // テキストの配置を設定
                  textAlign: 'center', // テキストの配置を設定

                }}
              >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const is1 = cell.column.id === 'Shop_name';
                  const is2 = cell.column.id === 'keikabi' || cell.column.id === 'C_button1' || cell.column.id === 'Copy'|| cell.column.id === 'new_product';
                    
                  return <td 
                  style={{
                    width: is1 ? '100px' : is2 ?'25px':'auto',   // delete または C_button1 のとき 50px、他は150px
                    textAlign: 'left',

                    //overflow: 'hidden',         // はみ出た部分を隠す
                    //whiteSpace: 'nowrap',       // テキストを1行にする
                    //textOverflow: 'ellipsis',   // はみ出たテキストを省略記号で表示
                  }}

                  {...cell.getCellProps()}>{cell.render('Cell')}
                  </td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <Modal_zaikolist show={show} handleClose={handleClose}>
        {selectedRow && (
          <div id="C_shiire_toroku" className="top-container37">
          <div className="top-containerhattyukanri1">
            <div className="top-container38">
              <span className="top-text31">発注&gt;編集</span>
            </div>

            <div className="top-container39">
            <div className="product-info-top">
                <div className="product-info1">

                  <div className="top-container40">
                    <div className="top-container41">
                      <span className="top-text32">商品名</span>
                      <input
                        type="text"
                        value = {name}
                        className="top-textinput16 input"
                        id = "input-readOnly"
                        readOnly
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="top-container42">
                      <span className="top-text33">品番</span>
                      <input
                        type="text"
                        value = {id}
                        onChange={(e) => setid(e.target.value)}
                        readOnly
                        id = "input-readOnly"
                        className="top-textinput17 input"
                      />
                    </div>
                  </div>

                  <div className="top-container43">
                    <div className="top-container44">
                      <span className="top-text34">トーマス在庫</span>
                      <input
                        type="text"
                        value = {Tormaszaiko}
                        readOnly
                        className="top-textinput18 input"
                        id = "input-readOnly"
                        onChange={(e) => setTormaszaiko(e.target.value)}
                      />
                    </div>
                    <div className="top-container45">
                      <span className="top-text35">仕入れ予定数</span>
                      <input
                        type="text"
                        value = {Shireyoteisu}
                        className="top-textinput19 input"
                        readOnly
                        id = "input-readOnly"
                        onChange={(e) => setSyukkataikisu(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="top-container48-1">
                    <div className="top-container49-1">
                      <span className="top-text37">出荷待機数</span>
                      <input
                        type="text"
                        value = {Syukkataikisu}
                        className="top-textinput21-1 input"
                        readOnly
                        id = "input-readOnly"
                        onChange={(e) => setSyukkataikisu(e.target.value)}
                      />
                    </div>

                      <div className="top-container51-1">
                        <span className="top-text38">未到着在庫</span>
                        <input
                          value = {Mitotyakusu}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          onChange={(e) => setMitotyakusu(e.target.value)}

                        ></input>
                    </div>

                  </div>

                  <div className="top-container48-1">

                      <div className="top-container51-11">
                        <span className="top-text38">平均納期</span>
                        <input
                          value = {ave_nouki}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          
                        ></input>
                      </div>
                      <div className="top-container51-1">
                        <span className="top-text38">過不足数</span>
                        <input
                          value = {kahusokusu}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          onChange={(e) => setkahusokusu(e.target.value)}

                        ></input>
                      </div>

                  </div>
                </div>

                <div className="product-info1">
                  <div className="top-container48-1-h">
                    <div className="top-container49-1">
                      <span className="top-text37">必要数</span>
                      <input
                        type="text"
                        value = {Hituyousu}
                        className="top-textinput21-1 input"
                        
                        id = "input-readOnly"
                        readOnly
                        
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">ショップ名</span>
                        <input
                        value = {Shop_name}
                          className="top-textinput21-2 input"
                          id = "input-readOnly"
                          readOnly
                         
                        ></input>
                      </div>

                  </div>


                  <div className="top-container48-1-sh">
                    <div className="top-container49-1">
                      <span className="top-text37">仕入れ値</span>
                      <input
                        type="text"
                        value = {Shireprice}
                        className="top-textinput21-1 input"
                        id = "input-readOnly"
                        readOnly
                        onChange={(e) => setShireprice(e.target.value)}
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">発注数</span>
                        <input
                          className="top-textinput21-2 input"
                          id = "input-readOnly"
                        readOnly
                          onChange={(e) => setHatyuusu(e.target.value)}
                          
                        ></input>
                      </div>

                  </div>
                  <div className="top-container48-1-co-1">
                    <div className="top-container49-1">
                      <span className="top-text37">カラー</span>
                      <input
                        type="text"
                        value = {color}
                        className="top-textinput21-1 input"
                        id = "input-readOnly"
                        readOnly
                        onChange={(e) => setcolor(e.target.value)}
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">サイズ</span>
                        <input
                        value = {size}
                          className="top-textinput21-2 input"
                          id = "input-readOnly"
                        readOnly
                          onChange={(e) => setsize(e.target.value)}
                          
                        ></input>
                      </div>

                  </div>


                  <div className="top-container48-1">
                    <div className="top-container49-1">
                      <span className="top-text37">取引先名</span>                    
                      <input
                          className="top-textinput21-2 input"
                          id = "input-readOnly"
                        readOnly
                          value = {Hiiresaki}
                          onChange={(e) => setHiiresaki(e.target.value)}

                        ></input>
                    </div>

                      <div className="top-container51-1">
                        <span className="top-text38">取引先名URL</span>

                        <a
                          href={Shiiresaki_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                          //className="top-textinput21-2"
                          className="link-style" // リンクのスタイルを適用
                          
                        >
                          {Shiiresaki_URL.length > 15 ? `${Shiiresaki_URL.slice(0, 15)}...` : Shiiresaki_URL}
                        </a>


                      </div>
                  </div>

                  

                </div>
              </div>
              <div className="top-container50">
                    <div className="top-container51-m">
                      <span className="top-text38">メモ</span>
                    </div>
                    <textarea
                      placeholder="メモ"
                      className="top-textarea-m textarea"
                      value = {emo_p}
                      id = "input-readOnly"
                        readOnly
                      onChange={(e) => setmemo_p(e.target.value)}

                    ></textarea>
                  </div>

            </div>


            
          </div>
        </div>
        )}
      </Modal_zaikolist>

    </div>
  );
};

/*
export default TableComponent3;*/



