import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import Papa from 'papaparse';
import axios from 'axios';
import encoding from 'encoding-japanese';
import './ModalComponent.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
  },
};

const ModalComponent =({ isOpen, onRequestClose ,updateTableData }) => {

  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;

  
  const [file1Status, setFile1Status] = useState(null);
  const [file2Status, setFile2Status] = useState(null);
  const [file3Status, setFile3Status] = useState(null);

  const [file1Data, setFile1Data] = useState([]);
  const [file2Data, setFile2Data] = useState([]);
  const [file3Data, setFile3Data] = useState([]);

  const [combinedData, setCombinedData] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file, index) => {

      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const detectedEncoding = encoding.detect(uint8Array);

        const text = encoding.convert(uint8Array, {
          to: 'UNICODE',
          from: 'SJIS',/*detectedEncoding,*/
          type: 'string'
          /*
          to: 'UTF-8',
          from: detectedEncoding,
          type: 'string'
          */

        });

        // 最初の行に改行コードが含まれている場合、それを削除する
      const cleanedText = text.replace(/^\s*\n/, '');

        Papa.parse(cleanedText, {
          complete: (result) => {
            console.log(result.data)

             // セル内の改行コードを削除
            const cleanedData = result.data.map(row => 
              row.map(cell => cell.replace(/\r?\n|\r/g, ''))
            );

            const firstCell = cleanedData[0][0];
            const firstCell2 = cleanedData[0][6];
            console.log(firstCell)
            if (firstCell2 === 'ピック数') {
              setFile3Status(`${file.name}：販売実績ファイル OK`);
              setFile3Data(cleanedData);
            } else {
              if (firstCell === '品番') {
                setFile1Status(`${file.name}：ベース商品ファイル OK`);
                setFile1Data(cleanedData);
              } else {
               
              }
              
            }
            
          
            if (firstCell === '入荷予定日') {
              setFile2Status(`${file.name}入荷予定ファイル OK`);
              setFile2Data(cleanedData);
            } else {
              
            }

            /*if (firstCell === '注文日') {
              setFile3Status(`${file.name}：販売実績ファイル OK`);
              setFile3Data(cleanedData);
            } else {
              
            }*/
           /*受注実績*/
           /*
            if (firstCell === '注文ID') {
              setFile3Status(`${file.name}：販売実績ファイル OK`);
              setFile3Data(cleanedData);
            } else {
              
            }*/
            
            
            

            

          },
          header: false,
        });
      };
      reader.readAsArrayBuffer(file);

    });
    
  }, [file1Data, file2Data,file3Data]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: true,
    
  });

  const handleCombineAndUpload = async () => {
    console.log(file1Data)
    console.log(file2Data)
    console.log(file3Data)
    // 空白行を削除する関数
  const removeEmptyRows = (data) => {
    return data.filter(row => row.some(cell => cell !== null && cell !== undefined && cell.toString().trim() !== ""));
  };

  // 空白行を削除
  const cleanedFile1Data = removeEmptyRows(file1Data);
  const cleanedFile2Data = removeEmptyRows(file2Data);
  const cleanedFile3Data = removeEmptyRows(file3Data);

  if (cleanedFile1Data.length > 0 && cleanedFile3Data.length > 0) { 
    // ファイル1の1行目（ヘッダー）を取得

    let headerRow = [...cleanedFile1Data[0]]; // ヘッダーをコピー
    headerRow.push("入荷予定数"); // ヘッダーに"入荷予定数"を追加
    headerRow.push("必要数"); // ヘッダーに"入荷予定数"を追加

    const dataRows = cleanedFile1Data.slice(1); // データ部分だけを切り取る
  
    // カラム1で検索し、カラム2の値を合計
    const updatedFile1 = dataRows.map(row1 => {
      const sum = cleanedFile2Data.reduce((acc, row2) => {
        if (row1[0] === row2[1]) {
          return acc + parseFloat(row2[2]);
        }
        return acc;
      }, 0);
      return [...row1, sum]; // sumを新しい列として追加
    });
  
    // ヘッダーに"入荷予定数"を追加して、updatedFile1に結合
    
    console.log("入荷予定数ADD END");
  
      
        // カラム1で検索し、カラム2の値を合計
        const updatedFile2 = updatedFile1.map(row1 => {
          const sum = cleanedFile3Data.reduce((acc, row2) => {
            //console.log(parseFloat(row2[6]))
            /*console.log("ファイル1")
            console.log(row1[0] )
            console.log("ファイル２")
            console.log(row2[18])
            console.log("==========")*/
            /*受注ファイルの時 */
            /*if (row1[0] === row2[1]) {
              return acc + parseFloat(row2[5]);
            }*/
            /*販売実績の時 */
            /*if (row1[0] === row2[36]) {
              return acc + parseFloat(row2[22]);
            }*/

            /*ピックの時*/
            if (row1[0] === row2[0]) {
              return acc + parseFloat(row2[6]);
            }
           
            return acc;

          }, 0);
          return [...row1, sum];
        });
        
        console.log("======発注数付与======")
        console.log(updatedFile2)
        const updatedFile1WithHeader = [headerRow, ...updatedFile2];
  
        console.log(updatedFile1WithHeader);

        console.log("======発注数付与 END ======")
      try {
        const response = await axios.post(`${apiUrl}?action=processFiles`, { updatedFile1WithHeader });
        const { result1, result2 } = response.data;
        console.log("====IMPORTFILE ST====")
        console.log(response)
        console.log("====IMPORTFILE1====")
        console.log(result1)
        console.log("====IMPORTFILE2====")
        console.log(result2)
        console.log("====IMPORTFILE END====")
        /*alert(`結果1: ${result1}, 結果2: ${result2}`);*/
        alert("インポート成功しました。")
        
        // テーブルデータを更新
        if (updateTableData.current) {
          updateTableData.current();
        }

        // モーダルを閉じる
        onRequestClose();
        
      } catch (error) {
        console.error('Error processing files', error);
      }
        

    } else {
      alert('両方のファイルをアップロードしてください。');
    }
  };

  const handleClear = () => {
    setFile1Status(null);
    setFile2Status(null);
    setFile3Status(null);
    setFile1Data([]);
    setFile2Data([]);
    setFile3Data([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="CSV File Dropzone"
    >
      <div className="dropzone-container" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>クリックまたはドロップ＆ドロップでファイルをアップロードしてください。</p>
      </div>
      <div className="status">
      <p>{file1Status}</p>
      <p>{file2Status}</p>
      <p>{file3Status}</p>
        
      </div>
      <button className="bu_update" onClick={handleCombineAndUpload}>データベースを更新</button>
      <button className="bu_clr" onClick={handleClear}>ファイルクリア</button>
      <button className="bu_close" onClick={onRequestClose}>閉じる</button>
    </Modal>
  );
};

export default ModalComponent;