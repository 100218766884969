import React from 'react';
import './Modal_zaikolist.css';

const Modal_zaikolist = ({ show, handleClose, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main-zaiko">
        {children}
        <button className ="bu_cl-p-zl" onClick={handleClose}>閉じる</button>
      </section>
    </div>
  );
};

export default Modal_zaikolist;