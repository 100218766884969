import React, { useEffect ,useRef}  from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { useState } from 'react';
import './top.css'
import TableComponent from '../components/table1'
import TableComponent2 from '../components/table2'
import TableComponent3 from '../components/table3'
import TableComponent4 from '../components/table4'
import ModalComponent from '../components/ModalComponent'
import TableComponent_shohin from '../components/table1_shohin' 
import { saveAs } from 'file-saver'; // ファイル保存用のライブラリ


function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}



const TOP = (props) => {

  // useStateフックをコンポーネントのトップレベルで宣言
  const [exportData, setExportData] = useState({ rows: [], columns: [] });
  const updateTableData = useRef(null);
  const handleExport = () => {
    const { rows, columns } = exportData;

    if (!rows || !columns) {
      alert('データが見つかりません');
      return;
    }

    console.log(rows);
    console.log(columns);
    console.log("外部テーブルデータ");

    // テーブルヘッダーをCSVの最初の行に追加
    const headers = columns.map(column => column.Header).join(',');

    // 元のデータ (rows) をCSV形式に変換
    const csvContent = rows.map(row => {
      return columns.map(column => row.original[column.accessor] || '').join(',');
    }).join('\n');

    // BOMを追加してUTF-8として認識させる
    const bom = '\uFEFF';

    // ヘッダーとデータを結合
    const fullCsvContent = `${headers}\n${csvContent}`;

    // 現在の日付を取得してファイル名に使用
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD形式
    const filename = `${today}_export.csv`;

    // Blobオブジェクトを作成し、BOMを含めたCSVデータを追加
    const blob = new Blob([bom + fullCsvContent], { type: 'text/csv;charset=utf-8;' });

    // ファイルをダウンロード
    saveAs(blob, filename);
  };

  const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL_LOCAL;
  
  const loginUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_LOGIN_API_BASE_URL_PROD 
        : process.env.REACT_APP_LOGIN_API_BASE_URL_LOCAL;

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      

      console.log(loginUrl)
        try {
            const response = await axios.get(`${loginUrl}?action=checkAuth`);
            console.log(response)
            if (!response.data.authenticated) {
                navigate('/'); // Redirect to login if not authenticated
            }
        } catch (error) {
            console.error('Error checking authentication:', error);
            navigate('/'); // Redirect to login on error
        }
    };

    checkAuth();
}, [navigate]);

  

  //apiUrl = 'http://localhost:3001'
  console.log(apiUrl)

  const handleLogout = () => {
    console.log("ログアウト")
    axios.post(`${loginUrl}?action=logout`)
      .then(response => {
        navigate('/');
      })
      .catch(error => {
        console.error('ログアウト中にエラーが発生しました', error);
      });
      
  };
 
  const today = new Date();
  const todayFormatted = formatDate(today);

  const [date, setDate] = React.useState(todayFormatted);
  
    // useStateフックを使用して、どのボタンがクリックされているかの状態を管理
    const [clickedButton, setClickedButton] = useState("button1");

    // ボタンのクリックイベントハンドラー
    const handleButtonClick = (buttonName) => {
      setClickedButton(buttonName);
    };
    /*企業登録*/

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [url, setUrl] = useState('');
  const [Person, setperson] = useState('');
  const [memo, setmemo] = useState('');

  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      const response = await axios.post(`${apiUrl}?action=addCampany`, { name,Person, url,number,memo },
        {
          headers: {
            'Content-Type': 'application/json',
            // 他に必要なヘッダーを追加
          },
          withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
        }
      );
      if (response.status === 200) {
        alert('登録が完了しました');
        setMessage('登録が完了しました');
        setName('');
        setNumber('');
        setUrl('');
      }
    } catch (error) {
      alert('データの登録に失敗しました');
      console.error('Error adding data:', error);
    }
  };


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="top-container">
      <Helmet>
        <title>TOP - 在庫管理システム</title>
        <meta property="og:title" content="TOP  - 在庫管理システム" />
      </Helmet>
      <div className="top-container01">
        <span className="top-text">在庫管理システム</span>
      </div>
      <div className="top-container02">
        <div className="top-container03">
          <div className="top-container04">
            <span className="top-text01">最新在庫管理</span>
            <div className="top-container05">
              <div className="top-container06"></div>
            </div>
            <button
              id="saishin-list"
              type="button"
              className="top-button button"
              
              onClick={() => handleButtonClick('button1')}
              style ={{backgroundColor : clickedButton == 'button1' ? '#caddf3' :  '#FFFFFF'}}

            >
              - 最新情報一覧
            </button>

            <button
              id="jyouhou_koshin"
              type="button"
              className="top-button01 button"
              onClick={() => handleButtonClick('button2')}
              style ={{backgroundColor : clickedButton == 'button2' ? '#caddf3' :  '#FFFFFF'}}
            >
              - 情報更新
            </button>
            <span className="top-text02">発注注管理</span>
            <button
              id="kanri_status"
              type="button"
              className="top-button02 button"
              onClick={() => handleButtonClick('button3')}
              style ={{backgroundColor : clickedButton == 'button3' ? '#caddf3' :  '#FFFFFF'}}
              
            >
              - 管理ステータス
            </button>
            {/*<button
              id="hattyu_syori"
              type="button"
              className="top-button03 button"
              onClick={() => handleButtonClick('button4')}
              style ={{backgroundColor : clickedButton == 'button4' ? '#caddf3' :  '#FFFFFF'}}
              
            >
              - 発注処理
            </button> */}
            
            <button
              id="shohin-list"
              type="button"
              className="top-button button"
              
              onClick={() => handleButtonClick('button7')}
              style ={{backgroundColor : clickedButton == 'button7' ? '#caddf3' :  '#FFFFFF'}}

            >
              - 商品情報
            </button>

            <button type="button" className="top-button05 button" onClick={handleLogout}>
              ログアウト
            </button>
          </div>
        </div>
        {/* 条件付きレンダリングで要素を表示/非表示 */}
        
        {clickedButton == 'button1'  && 
        <div id="C_sashin" className="top-container07">
          <div className="top-containersaishin">
            <div className="top-container08">
              <div className="top-container09">
                <span className="top-text03">在庫管理&gt;最新情報一覧</span>
               
                
              </div>
              <button type="button" className="top-button06 button"
              onClick={handleExport}
              >
                ↑エクスポート
              </button>
              <button type="button" className="top-button07 button" onClick={openModal}>
                ↓インポート
              </button>
              <ModalComponent isOpen={modalIsOpen} onRequestClose={closeModal} updateTableData={updateTableData} />

            </div>
            <TableComponent onExport={(rows, columns) => setExportData({ rows, columns })} updateTableData={updateTableData}  />
          </div>
        </div>}

        {clickedButton == 'button2'  && 
        <div id="C_rireki" className="top-container12">
          <div className="top-containersaishin1">
            <div className="top-container13">
              <span className="top-text09">在庫管理&gt;更新情報</span>

            </div>
            <TableComponent2></TableComponent2>
          </div>
        </div>}
        
        {clickedButton == 'button3'  && 
        <div id="C_kanri_status" className="top-container15">
          <div className="top-containerkanrist">
            <div className="top-container16">
              <span className="top-text12">発注管理&gt;発注ステータス</span>
              
            </div>
            <TableComponent3></TableComponent3>
          </div>
        </div>}

        {clickedButton == 'button4'  && 
        <div id="C_hatyuu" className="top-container19">
          <div className="top-containerhattyukanri">
            <div className="top-container20">
              <span className="top-text18">発注管理&gt;発注処理</span>
            </div>
            <div className="top-container21">
              <div className="top-container22">
                <span className="top-text19">在庫数　</span>
                <input
                  type="text"
                  placeholder="10"
                  className="top-textinput06 input"
                />
              </div>
              <div className="top-container23">
                <div className="top-container24">
                  <span className="top-text20">品番</span>
                  <input
                    type="text"
                    placeholder="nn-0001"
                    className="top-textinput07 input"
                  />
                </div>
                <div className="top-container25">
                  <span className="top-text21">登録日</span>
                  <input
                    type="text"
                    placeholder="2023/07/07"
                    className="top-textinput08 input"
                  />
                </div>
              </div>
              <div className="top-container26">
                <div className="top-container27">
                  <span className="top-text22">商品名</span>
                  <input
                    type="text"
                    placeholder="アクセサリ1"
                    className="top-textinput09 input"
                  />
                </div>
                <div className="top-container28">
                  <span className="top-text23">仕入れ値</span>
                  <input
                    type="text"
                    placeholder="1,980"
                    className="top-textinput10 input"
                  />
                </div>
              </div>
              <div className="top-container29">
                <div className="top-container30">
                  <span className="top-text24">仕入れ数</span>
                  <input
                    type="text"
                    placeholder="100"
                    className="top-textinput11 input"
                  />
                </div>
                <div className="top-container31">
                  <span className="top-text25">合計金額</span>
                  <input
                    type="text"
                    placeholder="198,000"
                    className="top-textinput12 input"
                  />
                </div>
              </div>
              <div className="top-container32">
                <button type="button" className="top-button08 button">
                  発注する
                </button>
              </div>
              <span className="top-text26">発注履歴</span>
            </div>
          </div>
        </div>}

        {clickedButton == 'button5'  && 
        <div id="C_shiiresaki" className="top-container33">
          <div className="top-containerkanrist1">
            <div className="top-container34">
              <span className="top-text27">発注管理&gt;仕入先</span>
              <button
                id="shinki_toroku"
                type="button"
                className="button shinki_toroku"
                onClick={() => handleButtonClick('button6')}
              >
                新規作成
              </button>
            </div>
            <TableComponent4></TableComponent4>
          </div>
        </div>}


        {clickedButton == 'button6'  && 
        <div id="C_shiire_toroku" className="top-container37">
          <div className="top-containerhattyukanri1">
            <div className="top-container38">
              <span className="top-text31">発注管理&gt;仕入れ先登録</span>
              <button
                id="to_torokurist"
                type="button"
                className="top-button10 button"
                onClick={() => handleButtonClick('button5')}
              >
                戻る
              </button>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="top-container39">
              <div className="top-container40">
                <div className="top-container41">
                  <span className="top-text32">業者名</span>
                  <input
                    type="text"
                    placeholder="nn-0001"
                    className="top-textinput16 input"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="top-container42">
                  <span className="top-text33">登録日</span>
                  <input
                    type="text"
                    value = {date}
                    readOnly
                    className="top-textinput17 input"
                  />
                </div>
              </div>
              <div className="top-container43">
                <div className="top-container44">
                  <span className="top-text34">担当者名</span>
                  <input
                    type="text"
                    placeholder="山田"
                    className="top-textinput18 input"
                    onChange={(e) => setperson(e.target.value)}
                  />
                </div>
                <div className="top-container45">
                  <span className="top-text35">連絡先</span>
                  <input
                    type="text"
                    placeholder="abc@gmail.com"
                    className="top-textinput19 input"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="top-container48">
                <div className="top-container49">
                  <span className="top-text37">URL</span>
                  <input
                    type="text"
                    placeholder="https://rion-lab-japan.com/"
                    className="top-textinput21 input"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              </div>
              <div className="top-container50">
                <div className="top-container51">
                  <span className="top-text38">メモ</span>
                </div>
                <textarea
                  placeholder="中国輸入"
                  className="top-textarea textarea"
                  onChange={(e) => setmemo(e.target.value)}

                ></textarea>
              </div>
              <div className="top-container52">
                <button  type="submit" className="top-button11 button">
                  登録する
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>}

        {clickedButton == 'button7'  && 
        <div id="C_rireki" className="top-container12">
          <div className="top-containersaishin1">
            <div className="top-container13">
              <span className="top-text09">在庫管理&gt;商品情報</span>

            </div>
            <TableComponent_shohin></TableComponent_shohin>
          </div>
        </div>}


      </div>
    </div>
  )
}

export default TOP
